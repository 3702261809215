<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <v-btn icon class="mr-2" @click="$router.go(-1)">
        <v-icon large>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="font-weight-bold">E-Test</span>
      <v-spacer />
      <v-btn
        color="indigo"
        class="mr-2"
        outlined
        depressed
        dark
        @click="
          $router.push({ name: 'CreateE-Test', query: { id: $route.query.id } })
        "
      >
        Edit
      </v-btn>
      <v-btn
        class="indigo darken-3"
        outlined
        depressed
        dark
        @click="
          $router.push({ name: 'ValueList', query: { id: $route.query.id } })
        "
      >
        Daftar Nilai
      </v-btn>
    </v-col>
    <v-col cols="12">
      <Etest readonly />
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
    Etest: () => import("./components/Etest.vue"),
  },
};
</script>
